import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    coach:[],
    user: [],
    template: [],
    alertMessage: []
};

const allCoachSlice = createSlice({
    name: 'coach',
    initialState,
    reducers: {
        setCoachs: (state, action) => {
            state.coach = action.payload;
        },      
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setTemplate: (state, action) => {
            state.template = action.payload;
        },
        setCoachAlertMessage: (state, action) => {
            state.alertMessage = action.payload;
        }  
    },
});
export const { setCoachs, setUser, setTemplate, setCoachAlertMessage } = allCoachSlice.actions;
export default allCoachSlice.reducer;
   