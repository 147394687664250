import { createSlice } from "@reduxjs/toolkit";
const initialState={
    clinics:[],
    clinicListing: [],
    ClinicRecord:[],
    ClinicHeader:[]
};
const addClinics= createSlice({
    name:'clinics',
    initialState,
    reducers:
    {
        setClinicsListing:(state,action)=>{
            state.clinicListing=action.payload;
        },
        setClinicRecord:(state,action)=>{
        state.ClinicRecord=action.payload;
        },
        setClinicHeaderInfo:(state,action)=>{
         state.ClinicHeader=action.payload;
        }
    }
});

  export const {setClinicsListing,setClinicRecord,setClinicHeaderInfo}=addClinics.actions;

  export default addClinics.reducer;