import { CNavItem } from "@coreui/react";

const _coachNav = [
  {
    component: CNavItem,
    name: "Patients",
    to: "/coachPatients",
  },
  {
    component: CNavItem,
    name: "RTM Dashboard",
    to: "/rtmDashboard",
  },
  {
    component: CNavItem,
    name: "Call Log",
    to: "/callLog",
  },
  {
    component: CNavItem,
    name: "Policy Reference",
    to: "/policyReference",
  },{
    component: CNavItem,
    name: "Task Template",
    to: "/Task",
  },
 
];

export default _coachNav;
