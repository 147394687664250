import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { toast } from "react-toastify";
import { setMDNotes } from "../reducers/providerReducers";

// Define a service using a base URL and expected endpoints
export const mdNotesSlice = createApi({
  reducerPath: "mdNotes",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['MD'],
  endpoints: (builder) => ({
    getAllMdNotes: builder.query({
      query: (id) => `/getmdnotes/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setMDNotes(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
      providesTags: ["MD"],
    }),
    postMdNotes: builder.mutation({
        query: (data) => ({
            url: `/createmdnotes`,
            method: 'POST',
            body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
              const { data } = await queryFulfilled;
              if (data) {
                toast.success("MD Notes Added Successfully");
              }
            } catch (error) {
                toast.error(error?.error?.data?.message);
            }
        },
        invalidatesTags: ["MD"],
    }),
    updateMdNotes: builder.mutation({
        query: ({data, id}) => ({
            url: `/updatemdnote/${id}`,
            method: 'PUT',
            body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
              const { data } = await queryFulfilled;
              if (data) {
                toast.success("MD Notes Updated Successfully");
              }
            } catch (error) {
                toast.error(error?.error?.data?.message);
            }
        },
        invalidatesTags: ["MD"],
    }),
    deleteMdNotes: builder.mutation({
        query: (id) => ({
            url: `/deletemdnote/${id}`,
            method: 'DELETE',
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
              const { data } = await queryFulfilled;
              if (data) {
                toast.success("MD Notes Deleted Successfully");
              }
            } catch (error) {
                toast.error(error?.error?.data?.message);
            }
        },
        invalidatesTags: ["MD"],
    }),
    deleteMonitoringHistory: builder.mutation({
      query: (id) => ({
          url: `/coach/deletecoachreport/${id}`,
          method: 'DELETE',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              toast.success("History Deleted Successfully");
            }
          } catch (error) {
              toast.error(error?.error?.data?.message);
          }
      },
      invalidatesTags: ["MD"],
  }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAllMdNotesQuery, usePostMdNotesMutation, useDeleteMdNotesMutation, useUpdateMdNotesMutation, useDeleteMonitoringHistoryMutation } = mdNotesSlice;
