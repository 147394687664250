import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setProvider } from '../reducers/providerReducers';

export const providerSlice = createApi({
  reducerPath: 'provider',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user?.token;
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllProvierList: builder.query({
      query: (id) => `/providerlisting/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProvider(data));
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
    }),
  }),
});

export const { useGetAllProvierListQuery } = providerSlice;
