import { CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Clinic",
    to: "/clinics",
  },
  {
    component: CNavItem,
    name: "Patient",
    to: "/patients",
  },
  {
    component: CNavItem,
    name: "Users",
    to: "/users",
  },
  {
    component: CNavItem,
    name: "Content Management",
    to: "/contentManagement",
  },
  {
    component: CNavItem,
    name: "Reports &\nAnalytics",
    to: "/report",
  },
  {
    component: CNavItem,
    name: "Task Template",
    to: "/Task",
  },
];

export default _nav;
