import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';
import { setPatients } from '../reducers/patientReducer';
import { setPatientsById } from '../reducers/patientReducer';
import { toast } from 'react-toastify';

export const patientSlice = createApi({
  reducerPath: 'patient',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user?.token;
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Patients'],
  endpoints: (builder) => ({
    getAllPatient: builder.query({
      query: ({ filterText, filter, coachId, providerId }) => {
        const params = new URLSearchParams();
    
        if (filterText) params.append('searchtext', filterText);
        if (filter?.userId) params.append('patientId', filter?.userId);
        if (filter?.userName) params.append('patientName', filter?.userName);
        if (filter?.registrationDateFrom) params.append('registerDateFrom', filter?.registrationDateFrom);
        if (filter?.registrationDateTo) params.append('registerDateTo', filter?.registrationDateTo);
        if(filter?.coachName) params.append("coachName", filter?.coachName);
        if (coachId) params.append('coachObjectId', coachId);
        if (providerId) params.append('providerObjectId', providerId);
        if (filter?.coachNotAssigned) params.append('coachAssigned', filter?.coachNotAssigned == true ? false : true);
    
        return `/allpatient?${params.toString()}`;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setPatients(data));
          }
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
      providesTags: ['Patients'],
    }),    
    assignCoachProviderToPatient: builder.mutation({
      query: ({payload, patientId}) => ({
        url: `/updateroleuser/${patientId}`,
        method: 'PUT',
        body: payload,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              toast.success("Coach and provider assigned successfully!");
            }
          } catch (err) {
            toast.error(`Error: ${err?.message || "Failed to assign coach and patient patient."}`);
          }
        },
        invalidatesTags: ['Patients'],
    }),
    approvePendingPatient: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/updateroleuser/${id}`,
        method: 'PUT',
        body: payload
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Patient approved successfully!");
          }
        } catch (err) {
          toast.error(`Error: ${err?.message || "Failed to approve patient."}`);
        }
      },
      invalidatesTags: ['Patients'],
    }),
    addPatient: builder.mutation({
      query: (payload) => ({
        url: `/addrolesuser`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Patients'],
    }),
    getPatientById:builder.query({
      query:(id)=>`/getpateintdetails/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPatientsById(data));
        } catch (err) {
          console.error('Query Error:', err);
        }
      },
    })
  }),
});

export const { useGetAllPatientQuery, useAssignCoachProviderToPatientMutation, useApprovePendingPatientMutation, useAddPatientMutation,useGetPatientByIdQuery } = patientSlice;
