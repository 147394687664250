import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null,
  userType: null,
  token: null,
  id: null,
  userData: null,
  user_id: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { email, userType, token, id, userData, user_id } = action.payload;
      state.email = email;
      state.userType = userType;
      state.token = token;
      state.id = id;
      state.user_id = user_id;
      state.userData = userData;
    },
    logout: () => {
    return initialState;
    },
  },
});

export const { setUserData, logout } = userSlice.actions;
export default userSlice.reducer;
