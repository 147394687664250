import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setChill } from "../reducers/patientUseFullResourcesSlice";
import { toast } from "react-toastify";

// Define a service using a base URL and expected endpoints
export const chillSlice = createApi({
  reducerPath: "chill",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Chill'],
  endpoints: (builder) => ({
    getAllChill: builder.query({
      query: () => `/admin/chilllist`, 
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setChill(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
      providesTags: ["Chill"],
    }),
    addChill: builder.mutation({
        query: (data) => ({
            url: `/admin/addChill`,
            method: 'POST',
            body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Chill added successfully!");
            }
          } catch (err) {
            toast.error(`Error: ${err?.message || "Failed to add chill."}`);
          }
        },        
        invalidatesTags: ["Chill"],
    }),
    deleteChill: builder.mutation({
        query: (id) => ({
            url: `/admin/deletechill/${id}`,
            method: 'PATCH',
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Chill deleted successfully!");
              }
            } catch (err) {
              toast.error(`Error: ${err?.message || "Failed to delete chill."}`);
            }
        },
        invalidatesTags: ["Chill"],
    }),
    updateChill: builder.mutation({
      query: ({data, id}) => ({
        url: `/admin/editchill/${id}`,
        method: 'PUT',
        body: data,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
          
          try {
            const { data } = await queryFulfilled;
            if(data){
              toast.success("Chill updated successfully!");
              }
            } catch (err) {
              toast.error(`Error: ${err?.message || "Failed to update chill."}`);
            }
        },
        invalidatesTags: ["Chill"],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAllChillQuery, useAddChillMutation, useDeleteChillMutation, useUpdateChillMutation } = chillSlice;
