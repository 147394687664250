import {
  CButton,
  CCloseButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar-edit";
import { useDispatch, useSelector } from "react-redux";
import Camera from "../../assets/icons/camera.png";
import Setting from "../../assets/icons/settingProfile.png";
import User from "../../assets/icons/user-profile.png";
import { useUpdateRoleMutation } from "../../redux/apis/login_api";
import { setUpdateProfile } from "../../redux/reducers/loginReducer";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";

const ProfileModal = ({ checked, avatarURL, setAvatarURL, profileModal, onCloseModal, setChecked }) => {

const getProfileData=useSelector((state)=>state?.user?.userData);
const token=useSelector((state)=>state?.user?.token);
const id=getProfileData?._id;

const dispatch=useDispatch();
  const [ updateRole ] = useUpdateRoleMutation();

  const fileUploadRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [imgCrop, setImgCrop] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    first_name:'' ,
    last_name: '',
    password: '',
    confirmPassword:'',
    avatarURL:avatarURL,
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target; // Get field name and value
    setFormData((prev) => ({
      ...prev,
      [name]: value, // Update the corresponding field
    }));
  };

 

  const handleChanges = async (e) => {
    if (e.target?.files?.[0]) {
      try {
        const uploadedFile = e.target.files[0];
        const formData=new FormData;
        formData.append('uploadedFile',uploadedFile);

        setImgCrop(URL.createObjectURL(uploadedFile));
        setFormData((prev) => ({
          ...prev,
          avatarFile: uploadedFile, 
        }));
      } catch (error) {
        console.error("Error handling image upload:", error);
      }
    }
  };

  const onCrop = (croppedImageURL) => {
    setImgCrop(croppedImageURL); 
  }; 

  const onClose = () => {
    setImgCrop(null);
  };

  const saveImg = () => {
    if (imgCrop) {
      setAvatarURL(imgCrop); 
      setFormData((prev) => ({ ...prev, avatarURL: imgCrop })); 
      setVisible(false); 
    }
  };
   const toggler = () => {
    setToggle(!toggle);
  };

  const togglerConfirm = () => {
    setToggleConfirm(!toggleConfirm);
  };

  const handleToggleChange = (event, type) => {
    setChecked((prevState) => ({
      ...prevState,
      [type]: event.target.checked,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!formData.id) {
      return; 
    }
    if (!formData.first_name || !formData.last_name) {
      setError("First name and Last name cannot be empty.");
      setTimeout(()=>{
        setError('');
      },3000);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Password and Confirm Password do not match.");
      setTimeout(()=>{
        setError('');
      },3000);
     
      return;
    }
    try {
      const payload={
        title:formData.title,
        first_name:formData.first_name,
        last_name:formData.last_name,
        password:formData.password,
        profile_img:formData.avatarURL
    };
     const result=await  updateRole({ id:id, payload: payload,token }).unwrap();

      if (result) {
  
        // Update the Redux store with the response data
        dispatch(setUpdateProfile(result));
      
      alert("Profile updated successfully!");
      onCloseModal();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  useEffect(() => {

    if (getProfileData) {
      setFormData((prev) => ({
        ...prev,
        title: getProfileData.title || prev.title,
        first_name: getProfileData.first_name || prev.first_name,
        last_name: getProfileData.last_name || prev.last_name,
        // profile_img: formData.profile_img || prev.profile_img,
        profile_img: formData?.avatarURL, 
        id: getProfileData._id || prev._id,
        token:token
      }));
    }
  }, [getProfileData, avatarURL]);
  return (
    <>
      <>
        <CModal
          size="lg"
          alignment="center"
          visible={profileModal}
          onClose={onCloseModal}
          aria-labelledby="VerticallyCenteredExample"
        >
          <div className="d-flex justify-content-between header-title-edit px-4 my-2">
            <CModalTitle className="title-edit">Profile</CModalTitle>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <CCloseButton
              className="text-reset modal-close-cross"
              onClick={onCloseModal}
            />
          </div>
          <hr className="my-0 mx-4 " />
          <div
            className="mx-4 d-flex "
            style={{ marginBottom: "-10px", marginTop: "10px" }}
          >
            <img src={User} height={18} width={18}></img>
            <div className="profile-basic px-2">Basic User Details</div>
          </div>

          <CModalBody>
            <div className="modal-body-container mt-1">
           
              <div className="row my-4 ">
                <div className="col-md-2 col-3 d-flex  position-relative ">
                  <img
                    src={formData?.avatarURL}
                    alt="Avatar"
                    className="img-wrap rounded-circle mx-3"
                    
                  />
                  <div className="profile-badge position-absolute pointer">
                    <img
                      src={Camera}
                      alt="Camera Icon"
                      onClick={() => {setVisible(true);}}
                      className="rounded-circle"
                    />
                  </div>
                </div>

                <div className="col-md-10 col-9">
                  <div className="row  mx-3">
                    <div className="col-12 col-md-2">
                      <label className="label-edit">Title *</label>
                      <select
                        type="text"
                        className="form-select form-control-input mt-1"
                        placeholder=""
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                      >
                        <option selected>Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                      </select>
                    </div>{" "}
                    <div className="col-12 col-md-5">
                      <label className="label-edit">First Name *</label>
                      <input
                        type="text"
                        className="form-control form-control-input mt-1"
                        placeholder=""
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </div>{" "}
                    <div className="col-12 col-md-5">
                      <label className="label-edit">Last Name *</label>
                      <input
                        type="text"
                        className="form-control form-control-input mt-1"
                        placeholder=""
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row  mx-3 mt-4">
                    <div className="col-12 col-md-6">
                      <label className="label-edit">Password *</label>
                      <input
                        type="password"
                        className="form-control form-control-input mt-1"
                        placeholder=""
                        id="rv-password"
                        name="password"
                        onChange={handleChange}
                        value={formData.password}
                      />
                      <p
                        id="rg-eye"
                        style={{ marginLeft: "88%", marginTop: "-38px" }}
                        // onClick={() => {
                        //   let rgpassword =
                        //     document.getElementById("rv-password");
                        //   if (rgpassword?.type === "password") {
                        //     document.getElementById("rv-password").type =
                        //       "text";
                        //   } else {
                        //     document.getElementById("rv-password").type =
                        //       "password";
                        //   }
                        // }}
                        onClick={() => {
                          const passwordInput = document.getElementById("rv-password");
                          passwordInput.type =
                            passwordInput.type === "password" ? "text" : "password";
                        }}
                      >
                        {toggle ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={toggler}
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-eye"
                            viewBox="0 0 16 16"
                            style={{
                              color: "#d1d1d1",
                            }}
                            id="rg-login-svg"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={toggler}
                            width="22"
                            height="22"
                            className="bi bi-eye"
                            viewBox="0 0 24 24"
                            style={{
                              color: "#d1d1d1",
                            }}
                            id="rg-login-svg"
                          >
                            <path
                              fill="currentColor"
                              fill-rule="evenodd"
                              d="M4.707 3.293a1 1 0 0 0-1.414 1.414l1.67 1.671C3.23 7.716 1.889 9.538 1.07 11.636a1 1 0 0 0 0 .728C2.803 16.806 6.884 20 12 20c1.935 0 3.73-.459 5.31-1.276l1.983 1.983a1 1 0 0 0 1.414-1.414l-2.501-2.501a.968.968 0 0 0-.038-.038l-3.328-3.328l-.011-.012a1.252 1.252 0 0 0-.012-.011l-4.22-4.22a.841.841 0 0 0-.023-.023L7.245 5.83a.999.999 0 0 0-.038-.037l-2.5-2.5Zm4.585 7.414a3 3 0 0 0 4.001 4.001l-4-4.001Zm1.554-4.64C11.222 6.022 11.606 6 12 6c4.074 0 7.38 2.443 8.919 6c-.34.787-.768 1.52-1.271 2.184a1 1 0 1 0 1.594 1.208a12.599 12.599 0 0 0 1.69-3.028a1 1 0 0 0 0-.728C21.197 7.194 17.116 4 12 4c-.47 0-.932.027-1.386.08a1 1 0 0 0 .232 1.986Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      </p>
                    </div>{" "}
                    <div className="col-12 col-md-6">
                      <label className="label-edit">Confirm Password * </label>
                      <input
                        type="new-password"
                        className="form-control form-control-input mt-1"
                        placeholder=""
                        id="rv-confirm-password"
                        name="confirmPassword"
                        onChange={handleChange}
                      />
                      <p
                        id="rg-eye"
                        style={{ marginLeft: "88%", marginTop: "-38px" }}
                        onClick={() => {
                          let rgpassword = document.getElementById(
                            "rv-confirm-password"
                          );
                          if (rgpassword?.type === "password") {
                            document.getElementById(
                              "rv-confirm-password"
                            ).type = "text";
                          } else {
                            document.getElementById(
                              "rv-confirm-password"
                            ).type = "password";
                          }
                        }}
                      >
                        {toggleConfirm ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={togglerConfirm}
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-eye"
                            viewBox="0 0 16 16"
                            style={{
                              color: "#d1d1d1",
                            }}
                            id="rg-login-svg"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={togglerConfirm}
                            width="22"
                            height="22"
                            className="bi bi-eye"
                            viewBox="0 0 24 24"
                            style={{
                              color: "#d1d1d1",
                            }}
                            id="rg-login-svg"
                          >
                            <path
                              fill="currentColor"
                              fill-rule="evenodd"
                              d="M4.707 3.293a1 1 0 0 0-1.414 1.414l1.67 1.671C3.23 7.716 1.889 9.538 1.07 11.636a1 1 0 0 0 0 .728C2.803 16.806 6.884 20 12 20c1.935 0 3.73-.459 5.31-1.276l1.983 1.983a1 1 0 0 0 1.414-1.414l-2.501-2.501a.968.968 0 0 0-.038-.038l-3.328-3.328l-.011-.012a1.252 1.252 0 0 0-.012-.011l-4.22-4.22a.841.841 0 0 0-.023-.023L7.245 5.83a.999.999 0 0 0-.038-.037l-2.5-2.5Zm4.585 7.414a3 3 0 0 0 4.001 4.001l-4-4.001Zm1.554-4.64C11.222 6.022 11.606 6 12 6c4.074 0 7.38 2.443 8.919 6c-.34.787-.768 1.52-1.271 2.184a1 1 0 1 0 1.594 1.208a12.599 12.599 0 0 0 1.69-3.028a1 1 0 0 0 0-.728C21.197 7.194 17.116 4 12 4c-.47 0-.932.027-1.386.08a1 1 0 0 0 .232 1.986Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      </p>
                    </div>
                    <div className="any">
                      Password must have at least 8 characters, including
                      number, an uppercase and symbol.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-3">
              <div className="d-flex mx-2">
                <img src={Setting} height={18} alt="Settings" />
                <div className="profile-basic px-2">Settings</div>
              </div>
              <div className="modal-body-container my-2">
                <div className="p-3">
                  {/* <!-- First Switch and Alerts Text --> */}
                  <div className=" ">
                    <div className="d-flex align-items-center">
                      <ToggleSwitch
                        checked={checked.alertChecked}
                        handleToggleChange={(e) =>
                          handleToggleChange(e, "alertChecked")
                        }
                      />

                      <div className="ms-2 d-flex justify-content-between">
                        <div>
                          <p className="blockquote roboto fs-14">Alerts</p>
                          <p className="blockquote-footer fs-12">
                            Keep track of all alerts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Second Switch and Notifications Text --> */}

                  <div className="d-flex align-items-center">
                    <ToggleSwitch
                      checked={checked.notificationChecked}
                      handleToggleChange={(e) =>
                        handleToggleChange(e, "notificationChecked")
                      }
                    />
                    <div className="ms-2 d-flex justify-content-between">
                      <div className="blockquote roboto fs-14 mb-1">
                        <p>Notifications</p>
                        <p className="blockquote-footer fs-12">
                          Keep Taps of all in app Notifications.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-12 mt-4">
              <div className="row pt-2">
                <div className="col-md-12 text-end">
                  <button className="btn btn-cancel-user " onClick={onCloseModal}>
                    Cancel
                  </button>
                  <button
                    className="btn fw-bold btn-save ms-2 text-white"
                    onClick={(e) => handleSave(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <br />
          </CModalBody>
        </CModal>
      </>
     
      <>
        <CModal
          backdrop="static"
          id="register-page"
          alignment="center"
          scrollable
          visible={visible}
          aria-labelledby="ScrollingLongContentExampleLabel2"
        >
          <CModalBody id="register-page">
            <input
              type="file"
              id="file"
              ref={fileUploadRef}
              onChange={handleChanges}
              hidden
            />
            <Avatar
              onCrop={onCrop}
              onCloe={onClose}
              name="avatar"
              width="auto"
              height={350}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={1.2}
              rotate={0}
              label="Upload a Photo"
            />
          </CModalBody>
          <CModalFooter className="d-flex justify-content-between">
            <CButton color="primary" onClick={() => setVisible(false)}>
              Cancel
            </CButton>
            <CButton color="primary" onClick={() => saveImg()}>
              Upload
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    </>
  );
};

export default ProfileModal;
