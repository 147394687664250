import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  provider: [],
  MDNotes: []
};

const allProviderSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    setMDNotes: (state, action) => {
      state.MDNotes = action.payload;
    },
  },
});

export const { setProvider, setMDNotes } = allProviderSlice.actions;

export default allProviderSlice.reducer;
