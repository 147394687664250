import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { base_url } from '../../global/global';

export const roleSlice = createApi({
  reducerPath: 'role',
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
  updateRole: builder.mutation({
        query: ({ id, payload}) => ({
          url: `/updateroleuser/${id}`,
          method: 'PUT',
          body: payload,
        }),
        
      }),
      
  }),
  
});

export const { useUpdateRoleMutation } = roleSlice;
