import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setTips } from "../reducers/patientUseFullResourcesSlice";
import { toast } from "react-toastify";

// Define a service using a base URL and expected endpoints
export const tipsSlice = createApi({
  reducerPath: "tips",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      // If the token exists, set it in the Authorization header
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Tips'],
  endpoints: (builder) => ({
    getAllTips: builder.query({
      query: () => `/admin/tiplist`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setTips(data));
          }
        } catch (error) {
          console.error("Error fetching coach data:", error);
        }
      },
      providesTags: ["Tips"],
    }),
    deleteTips: builder.mutation({
      query: (id) => ({
        url: `/admin/deletetip/${id}`,
        method: 'PATCH',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Tip deleted successfully!");
          }
        } catch (err) {
          toast.error(err?.error?.data?.message);
        }
      },
      invalidatesTags: ["Tips"],
    }),
    addTips: builder.mutation({
      query: (data) => ({
        url: `/admin/addtip`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Tip added successfully!");
          }
        } catch (err) {
          toast.error(err?.error?.data?.message);
        }
      },
      invalidatesTags: ["Tips"],
    }),
    updateTips: builder.mutation({
      query: ({ data, id }) => ({
        url: `/admin/edittip/${id}`,
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            toast.success("Tip updated successfully!");
          }
        } catch (err) {
          toast.error(err?.error?.data?.message);
        }
      },
      invalidatesTags: ["Tips"],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAllTipsQuery, useDeleteTipsMutation, useAddTipsMutation, useUpdateTipsMutation } = tipsSlice;
