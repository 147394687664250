import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../global/global";
import { setClinicHeaderInfo, setClinicsListing } from "../reducers/clinicReducer";
import { setClinicRecord } from "../reducers/clinicReducer";
import { toast } from "react-toastify";

export const clinicSlice = createApi({
  reducerPath: "clinicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.user?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Clinics","Clinic","updateClinic"],
  endpoints: (builder) => ({
    addClinic: builder.mutation({
      query: (clinicData) => ({
        url: "/addclinic",
        method: "POST",
        body: clinicData,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Clinic Added Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags: ["Clinics"],
    }),
    getClinic: builder.query({
      query: ({clinicData,filter}) => ({
      url: `/getcliniclist?searchtext=${filter?.filterText?filter?.filterText:''}&datefrom=${filter?.startDate?filter?.startDate:""}&dateto=${filter?.endDate?filter?.endDate:""}&status=${filter?.status?filter?.status:""}`,
        method: "GET",
        body: clinicData,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setClinicsListing(data));
        } catch (err) {
          console.error("Query Error:", err);
        }
      },
      providesTags: ["Clinics"],
    }),

    getClinicRecord:builder.query({
      query:({id,filter})=>`/clinicrecord/${id}?searchtext=${filter?.filterText?filter?.filterText:''}&datefrom=${filter?.startDate?filter?.startDate:""}&dateto=${filter?.endDate?filter?.endDate:""}&status=${filter?.status?filter?.status:""}`,
    
     async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(setClinicRecord(data));
        dispatch(setClinicHeaderInfo({
          clinic_name:data?.data?.clinic_name,
          userName:data?.data?.userName,
          email:data?.data?.email,
          cell_number:data?.data?.cell_number,
          address:data?.data?.address_line,
          city:data?.data?.city,
          state:data?.data?.state,
          zipcode:data?.data?.zipcode
        }));
      } catch (err) {
        console.error("Query Error:", err);
      }
    },
    providesTags: ["Clinic"]
    }),
    addClinicUser: builder.mutation({
      query: (userData) => ({
        url: '/addrolesuser',
        method: 'POST',
        body: userData,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Clinic User Added Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags: ["Clinic"]
    }),
    deleteClinicUser: builder.mutation({
      query: (id) => ({
        url: `/deleteuserrole/${id}`,
        method: 'PATCH'
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Clinic User Deleted Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags: ["Clinic"]
    }),
    updateClinicUser:builder.mutation({
      query:({userId,updateData})=>({
        url:`/updateroleuser/${userId}`,
        method:'PUT',
        body:updateData
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Updated Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags:["Clinic"]
    }), 

    updateClinicPatient: builder.mutation({
      query: (id) => ({
        url: `/updateclinic/${id}`,
        method: 'PATCH'
      }),
      async onQueryStart(arg, { queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          if(data){
            toast.success("Clinic Updated Successfully");
            }
          } catch (err) {
            toast.error(err?.error?.data?.message);
          }
        }, 
      invalidatesTags: ["Clinic"]
    }),

  }),
});

export const { useAddClinicMutation, useGetClinicQuery,useGetClinicRecordQuery,useAddClinicUserMutation,useUpdateClinicUserMutation,useDeleteClinicUserMutation,useUpdateClinicPatientMutation } = clinicSlice;

