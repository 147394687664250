import routeConfig from "./routes";
import { useSelector } from "react-redux";

const useSelectedRole = () => {
  const { adminRoutes, providerRoutes, coachRoutes, clinicAdminRoutes } = routeConfig;
  const userType = useSelector((state) => state.user.userType);

  const selectedRoutes = userType === "admin" ? adminRoutes : userType === "coach" ? coachRoutes : userType === "provider" ? providerRoutes : clinicAdminRoutes;

  return selectedRoutes;
};

export default useSelectedRole;
