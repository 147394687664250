import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import useSelectedRole from '../SelectedRole';

const AppContent = () => {

  const selectedRoutes = useSelectedRole();

  return (
    <>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          
          {selectedRoutes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })};
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default React.memo(AppContent);
