import { CNavItem } from "@coreui/react";

const _clinicnav = [
  {
    component: CNavItem,
    name: "Patients",
    to: "/patients",
  },
  {
    component: CNavItem,
    name: "Billing Reports",
    //to: "/patients",
  },
];

export default _clinicnav;