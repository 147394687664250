import { createSlice } from "@reduxjs/toolkit";

const initialState={
    login:[],
    updateProfile:[]
};

const allLoginSlice=createSlice({
    name:'login',
    initialState,
    reducers:{
        setLogin:(state,action)=>{
          state.login=action.payload;
        },
        setUpdateProfile:(state,action)=>{
          state.updateProfile=action.payload;
        }
    }
});
export  const {setLogin,setUpdateProfile}=allLoginSlice.actions;
export default allLoginSlice.reducer;