import React from "react";
import avatar8 from "../assets/avatars/3.png";
import bellLogo from "../assets/icons/bell_Icon.png";
import ReviveLogo from  '../assets/brand/logo/GreenRevive.png';
import {
  CAvatar,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import AppHeaderDropdown from "./header/AppHeaderDropdown";
import { useSelector } from "react-redux";

const AppHeader = ({userType}) => {
  const [avatarURL, setAvatarURL] = React.useState(avatar8);

  const userName = useSelector((state) => state?.user?.userData);
  
  return (
    <div>
      <CHeader position="sticky" className="">
        <CContainer fluid>
          <div id="myDropdown" className="dropdown-content">
          </div>
          <CHeaderBrand className="mx-auto d-md-none" to="/">
          </CHeaderBrand>
          <CHeaderNav className="d-md-flex me-auto">
            {userType === "Provider" ?  <img src={ReviveLogo} alt="Revive Logo"/> :"" }
         
          </CHeaderNav>
          &nbsp;&nbsp;
          <CHeaderNav>
            <CNavItem>
              <CNavLink style={{ cursor: "pointer", display: "flex" }}>
                <div className="headerbell" style={{ marginRight: "20px" }}>
                </div>
                <div className="headerbell px-4">
                  <img src={bellLogo} height="25" />
                </div>
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
          <CAvatar src={avatarURL} size="md" />
          <div className="rv-info">
            <div className="col-md-12 col-sm-12">
              <strong className="rv-drp">{userName?.userType === "clinic admin" ? userName?.userName : `${userName?.first_name} ${userName?.last_name}`}</strong>
            </div>
            <div className="w-100 d-none d-md-block fs-5"></div>
            <div className="col-12 col-sm-12 rv-drp-us ">{userName?.userType}</div>
          </div>
          <CHeaderNav className="ms-3">
            <AppHeaderDropdown setAvatarURL = {setAvatarURL}  avatarURL = {avatarURL}/>
          </CHeaderNav>
        </CContainer>
      </CHeader>
    </div>
  );
};

export default AppHeader;
